<template>
  <div>
    <v-list-item
      v-if="!menuItem.items && !menuItem.fullLink && !menuItem.hide"
      :id="`${menuItem.text}-${menuItem.key}`"
      :key="`${menuItem.text}-${menuItem.key}`"
      :active="menuItem.value"
      :to="menuItem.fullLink ? menuItem.link : { name: menuItem.link, params: { lang: $current_lang } }"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      active-class="customDrawerSelect"
      :value="menuItem.value"
      link
      :data-cy="menuItem.text"
    >
      <template #prepend>
        <v-icon :small="small" :class="menuLinkText">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </template>
      <v-list-item-title :class="[menuLinkText, 'custom-color']">
        {{ menuItem.text }}
      </v-list-item-title>
    </v-list-item>

    <!-- Legacy by moving to Vuetify 3 -->
    <!-- <v-list-item
      v-if="!menuItem.items && menuItem.fullLink && defaultFrontend.logServerUrl && menuItem.specKey === 'logsServer'"
      :id="`${menuItem.text}-${menuItem.key}`"
      :key="`${menuItem.text}-${menuItem.key}`"
      :active="menuItem.value"
      :href="defaultFrontend.logServerUrl"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      active-class="text-primary"
      :value="menuItem.value"
      link
      target="_blank"
      :data-cy="menuItem.text"
    >
      <template #prepend>
        <v-icon :small="small" :class="menuLinkText">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </template>
      <v-list-item-title :class="menuLinkText">
        {{ menuItem.text }}
      </v-list-item-title>
    </v-list-item>

    <v-list-item
      v-if="!menuItem.items && menuItem.fullLink && menuItem.specKey === 'serviceDesk'"
      :id="`${menuItem.text}-${menuItem.key}`"
      :key="`${menuItem.text}-${menuItem.key}`"
      :active="menuItem.value"
      href="https://damudo.atlassian.net/servicedesk/"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      :value="menuItem.value"
      active-class="text-primary"
      link
      target="_blank"
    >
      <template #prepend>
        <v-icon :small="small" :class="menuLinkText">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </template>
      <v-list-item-title :class="menuLinkText">
        {{ menuItem.text }}
      </v-list-item-title>
    </v-list-item>

    <v-list-item
      v-if="!menuItem.items && menuItem.fullLink && defaultFrontend.metricServerUrl && menuItem.specKey === 'metricServer'"
      :id="`${menuItem.text}-${menuItem.key}`"
      :key="`${menuItem.text}-${menuItem.key}`"
      :active="menuItem.value"
      :href="defaultFrontend.metricServerUrl"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      :value="menuItem.value"
      active-class="text-primary"
      color="primary"
      link
      target="_blank"
    >
      <template #prepend>
        <v-icon :small="small" :class="menuLinkText">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </template>
      <v-list-item-title :class="menuLinkText">
        {{ menuItem.text }}
      </v-list-item-title>
    </v-list-item>

    <v-list-item
      v-if="!menuItem.items && menuItem.fullLink && defaultFrontend.visualisationServerUrl && menuItem.specKey === 'visualisationServer'"
      :id="`${menuItem.text}-${menuItem.key}`"
      :key="`${menuItem.text}-${menuItem.key}`"
      :active="menuItem.value"
      :href="defaultFrontend.visualisationServerUrl"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      :value="menuItemValue"
      active-class="text-primary"
      link
      target="_blank"
    >
      <template #prepend>
        <v-icon :small="small" :class="menuLinkText">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </template>
      <v-list-item-title :class=" {'text-white': $vuetify.theme.dark, 'text-grey ': menuItem.disabled }">
        {{ menuItem.text }}
      </v-list-item-title>
    </v-list-item> -->

    <!-- <v-list-item
      v-if="!menuItem.items && menuItem.fullLink && menuItem.specKey === 'swagger'"
      :id="`${menuItem.text}-${menuItem.key}`"
      :key="`${menuItem.text}-${menuItem.key}`"
      :active="menuItem.value"
      href="/api/swagger-ui/index.html"
      :exact="menuItem.exact"
      :value="menuItem.value"
      :disabled="menuItem.disabled"
      active-class="text-primary"
      link
      target="_blank"
    >
      <template #prepend>
        <v-icon :small="small" :class="menuLinkText">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </template>
      <v-list-item-title :class="{ 'custom-nav-color': !isThemeDark,'text-white': isThemeDark, 'text-grey': menuItem.disabled }">
        {{ menuItem.text }}
      </v-list-item-title>
    </v-list-item> -->

    <v-list-group
      v-if="menuItem.items && !menuItem.hide"
      :id="`${menuItem.value}-${menuItem.text}-${menuItem.key}`"
      :key="`${menuItem.text}-${menuItem.key}`"
      :value="menuItem.value"
      link
    >
      <template #activator="{ props }">
        <v-list-item
          v-bind="props"
          :key="`${menuItem.text}-${menuItem.key}`"
          :data-cy="menuItem.text"
        >
          <template #prepend>
            <v-icon
              v-if="!subgroup"
              class="custom-nav-color"
              :size="small ? 'small' : 'default'"
            >
              {{ menuItem.icon || 'mdi-circle-medium' }}
            </v-icon>
          </template>
          <v-list-item-title class="latest-nav-custom-color">
            {{ menuItem.text }}
          </v-list-item-title>
        </v-list-item>
      </template>

      <v-list-item
        v-for="(subItem) in menuItem.items.filter((item) => !item.hide)"
        :id="`${subItem.text}-${subItem.key}`"
        :key="`${subItem.text}-${subItem.key}`"
        :active="subItem.value"
        v-bind="subItem.fullLink
          ? { href: subItem.link, target: '_blank' }
          : { to: { name: subItem.link, params: { lang: $current_lang } } }"
        :exact="subItem.exact"
        :disabled="subItem.disabled"
        active-class="customDrawerSelect"
        :value="subItem.value"
        link
        :data-cy="subItem.text"
      >
        <template #prepend>
          <v-icon :small="small" :class="menuLinkText">
            {{ subItem.icon || 'mdi-circle-medium' }}
          </v-icon>
        </template>
        <v-list-item-title :class="[menuLinkText, 'custom-color']">
          {{ subItem.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list-group>
  </div>
</template>

<script>
import { bus } from '@/main'
import { mapState } from 'vuex'
import { useTheme } from 'vuetify'
/*
|---------------------------------------------------------------------
| Navigation Menu Item Component
|---------------------------------------------------------------------
|
| Navigation items for the FlowyNavMenu component
|
*/
export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {}
    },
    subgroup: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('app', ['defaultFrontend', 'userSettings']),
    menuItemValue() {
      return this.menuItem.regex || this.menuItem.link ? this.$route.path.includes(this.menuItem.regex) || this.$route.path.includes(this.menuItem.link) : false
    },
    menuLinkText() {
      return { 'text-white': this.isThemeDark, 'text-grey': this.menuItem.disabled }
    },
    isThemeDark() {
      const theme = useTheme()
      return theme.global.name.value === 'dark'
    }
  },
  methods: {
    refreshData(name) {
      bus.$emit('refreshData', name)
    }
  }
}
</script>

<style lang="scss">
.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  line-height: 1.25rem;
}

.latest-nav-custom-color {
  color: rgb(var(--v-theme-customNavColor)) !important;
}

.v-list-item--active .custom-color{
  color: white !important;
}


</style>
