// import Vue from 'vue'
import { createStore } from 'vuex'

// Global vuex
import AppModule from './app'

// Vue.use(Vuex)

/**
 * Main Vuex Store
 */
export const store = createStore({
  modules: {
    app: AppModule
  }
});

export default store
