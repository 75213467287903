<template>
  <v-menu class="step-context-menu">
    <template #activator="{ props }">
      <v-btn
        icon
        variant="text"
        v-bind="props"
        :color="isThemeDark ? 'primary' : 'secondary'"
        class="pr-4"
        style="align-self: center"
      >
        <v-icon size="large">
          mdi-dots-vertical
        </v-icon>
      </v-btn>
    </template>

    <v-list density="comfortable">
      <v-list-item class="pb-1">
        <v-tooltip location="top">
          <template #activator="{ props }">
            <v-btn
              icon
              color="primary"
              variant="text"
              v-bind="props"
              @click="copyStep(item)"
            >
              <v-icon size="24">
                mdi-vector-combine
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $lang.actions.copy }}</span>
        </v-tooltip>

        <v-tooltip location="top">
          <template #activator="{ props }">
            <v-btn
              icon
              color="info"
              variant="text"
              v-bind="props"
              class="ml-3"
              @click="pasteStepBefore(item)"
            >
              <v-icon size="24">
                mdi-content-paste
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $lang.actions.pasteBefore }}</span>
        </v-tooltip>
        <v-tooltip location="top">
          <template #activator="{ props }">
            <v-btn
              icon
              color="success"
              variant="text"
              v-bind="props"
              class="ml-3"
              @click="pasteStepAfter(item)"
            >
              <v-icon size="24">
                mdi-content-paste
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $lang.actions.pasteAfter }}</span>
        </v-tooltip>
        <v-tooltip location="top">
          <template #activator="{ props }">
            <v-btn
              icon
              color="error"
              variant="text"
              v-bind="props"
              class="ml-3"
              @click="pasteStep(item)"
            >
              <v-icon size="24">
                mdi-content-paste
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $lang.actions.pasteInstead }}</span>
        </v-tooltip>
      </v-list-item>
      <v-divider />
      <v-list-item
        density="comfortable"
        link
        @click="deleteStep(item)"
      >
        <template #default>
          <div class="d-flex align-center">
            <v-icon
              slot="prepend"
              color="error"
              class="mr-4"
            >
              mdi-trash-can-outline
            </v-icon>
            <v-list-item-title>{{ $lang.actions.delete }}</v-list-item-title>
          </div>
        </template>
      </v-list-item>
      <v-divider />
      <v-list-item
        density="comfortable"
        link
        @click="duplicateStep(item)"
      >
        <template #default>
          <div class="d-flex align-center">
            <v-icon
              slot="prepend"
              color="primary"
              class="mr-4"
            >
              mdi-content-duplicate
            </v-icon>
            <v-list-item-title>{{ $lang.actions.duplicate }}</v-list-item-title>
          </div>
        </template>
      </v-list-item>
      <v-list-item
        density="comfortable"
        link
        @click="linkToStep(item)"
      >
        <template #default>
          <div class="d-flex align-center">
            <v-icon
              slot="prepend"
              color="primary"
              class="mr-4"
            >
              mdi-link-box-variant-outline
            </v-icon>
            <v-list-item-title>{{ $lang.actions.copyLink }}</v-list-item-title>
          </div>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import copy from 'copy-to-clipboard'
import { useTheme } from 'vuetify'

export default {
  name: 'StepContextMenu',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    isThemeDark() {
      const theme = useTheme()
      return theme.global.name.value === 'dark'
    }
  },
  methods: {
    linkToStep(item) {
      if (item && item.localId) {
        const fullPath = window.location.href.split('#')[0] + `#${item.localId}`

        window.location.hash = `${item.localId}`
        copy(fullPath)
      }
    },
    deleteStep(item) {
      this.$emit('deleteStep', item)
    },
    copyStep(item) {
      localStorage.itemStorage = JSON.stringify(item)
      this.showSnack(this.$lang.success.stepCopy)
    },
    showSnack(text) {
      this.$emit('showSnack', text)
    },
    pasteStep(item) {
      this.$emit('pasteStep', item)
    },
    pasteStepBefore(item) {
      this.$emit('pasteStepBefore', item)
    },
    pasteStepAfter(item) {
      this.$emit('pasteStepAfter', item)
    },
    duplicateStep(item) {
      this.$emit('duplicateStep', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.step-context-menu .v-list-item.v-list-item--link {
  padding: 18px 30px;
}
</style>
