import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import JsonViewer from 'vue-json-viewer';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';
// import Lang from 'vuejs-localization';
import i18n from "./plugins/i18n"
import localizationCompatPlugin from './plugins/localizationCompatPlugin';
// import DatetimePicker from 'vuetify-datetime-picker';
import vueDebounce from 'vue-debounce';
import Timeline from './pages/processes/ProcessTimeline';
import auth from '@/auth/index';
import { setDomain } from '@/utils/api';
// Import Filters (Moved to just separate files that may be imported - after moving to Vue 3)
// import './filters/capitalize';
// import './filters/lowercase'; // Isn't rewritten from Vue 2, as we don't use it for now
// import './filters/uppercase'; // Isn't rewritten from Vue 2, as we don't use it for now
// import './filters/placeholder'; // Isn't rewritten from Vue 2, as we don't use it for now
// import './filters/trim'; // Isn't rewritten from Vue 2, as we don't use it for now
// import './filters/formatDate';
// import './filters/formatDateHour';
// import './filters/formatDateTime';
// import './filters/formatDateTimePrecise';
// import './filters/formatIsoStart'; // Isn't rewritten from Vue 2, as we don't use it for now
// import './filters/formatIsoEnd'; // Isn't rewritten from Vue 2, as we don't use it for now
// import './filters/formatIsoEndFullTime'; // Isn't rewritten from Vue 2, as we don't use it for now
// import './filters/timeUnits';
// import './filters/sizeUnits'; // Isn't rewritten from Vue 2, as we don't use it for now
// import './filters/javaVariableConventionRules';
// import './filters/checkComplexRegex';

// Import Styles
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
// import 'vuetify/styles';
// import './assets/scss/theme.scss';
import 'animate.css';
import 'flatpickr/dist/flatpickr.css';

// Localization
// Lang.requireAll(require.context('./lang', true, /\.js$/));

// Configure Axios Interceptors
setDomain('/api');

axios.interceptors.request.use((config) => {
  config.headers.common['x-auth-token'] = auth.isLoggedIn() ? auth.getToken() : 'guest';
  return config;
});

axios.interceptors.response.use(
  (response) => {
    if (response?.status === 401) auth.logout(notProtectedRoute());
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) auth.logout(notProtectedRoute());
    return Promise.reject(error);
  }
);

// bus Replacement (Custom implementation for Vue 3)
export const bus = {
  events: new Map(), // Store events for easier management

  $emit(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },

  $on(event, callback) {
    if (!this.events.has(event)) {
      const listener = (e) => callback(e.detail);
      document.addEventListener(event, listener);
      this.events.set(event, listener);
    }
  },

  $off(event) {
    if (this.events.has(event)) {
      document.removeEventListener(event, this.events.get(event));
      this.events.delete(event);
    }
  }
};

const notProtectedRoute = () => {
  return !['login', 'set-password'].includes(router.currentRoute.value.name);
};

// Create Vue App
const app = createApp(App);

// Global Components
app.component('Timeline', Timeline);

// Plugins
app.use(vuetify);
app.use(store);
app.use(router);
app.use(i18n);
app.use(localizationCompatPlugin, i18n);
app.use(vueDebounce, {
  listenTo: 'input', // Event to debounce (default is 'input')
  defaultTime: '300ms' // Default debounce time
});
app.directive('debounce', vueDebounce({ lock: true }))
// app.use(DatetimePicker);
app.use(JsonViewer);

// Mount Application
// app.config.globalProperties.$bus = EventBus; // Attach EventBus globally
router.isReady().then(() => app.mount('#app'))
