<template>
  <div class="basic">
    <v-btn
      icon
      size="medium"
      variant="text"
      :color="!isUpButtonDisabled && isThemeDark ? 'primary' : 'secondary'"
      class="up"
      :disabled="isUpButtonDisabled"
      @click="$emit('move', 'up')"
    >
      <v-icon size="medium">
        mdi-arrow-up
      </v-icon>
    </v-btn>
    <v-btn
      icon
      size="medium"
      variant="text"
      :color="!isDownButtonDisabled && isThemeDark ? 'primary' : 'secondary'"
      class="down"
      :disabled="isDownButtonDisabled"
      @click="$emit('move', 'down')"
    >
      <v-icon size="medium">
        mdi-arrow-down
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { useTheme } from 'vuetify'

export default {
  props: {
    stepLength: {
      type: Number,
      default: () => {
        return 0
      }
    },
    stepDepth: {
      type: Number,
      default: () => {
        return 0
      }
    },
    localId: {
      type: String,
      default: () => {
        return ''
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isException: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canGoUp: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canGoDown: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  computed: {
    isThemeDark() {
      const theme = useTheme()
      return theme.global.name.value === 'dark'
    },
    isUpButtonDisabled() {
      return this.stepLength === 1 || this.getFirstPArtOfLocalId(this.localId) === '1' || !this.canEdit
    },
    isDownButtonDisabled() {
      return this.isException ? !this.canGoDown : (this.stepLength === 1 || this.getFirstPArtOfLocalId(this.localId) === String(this.stepLength) || !this.canEdit)
    }
  },
  methods: {
    getFirstPArtOfLocalId(localId) {
      return localId ? localId.split('.')[0] : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.basic {
  position: relative;
  height: 54px;
  width: 45px;
}

.left {
  position: absolute;
  top: 14px;
  left: 0;
}

.right {
  position: absolute;
  top: 14px;
  right: 0;
}

.up {
  position: absolute;
  top: 0;
  left: 16px;
}

.down {
  position: absolute;
  bottom: 0;
  left: 16px;
}

.v-btn.v-btn--disabled {
  background-color: transparent !important;
}
</style>
