<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { defineComponent, provide } from 'vue';
import { useDisplay } from 'vuetify';

export default defineComponent({
  name: 'DisplayPluginOptionsAPIProvider',
  setup() {
    const display = useDisplay();
    provide('display', display);
    return {};
  }
});
</script>
