<template>
  <v-list
    nav
    density="compact"
    bg-color="transparent"
  >
    <div v-for="(item, index) in menu" :key="index">
      <div v-if="item.text && !rail" class="pa-1 mt-2 text-overline">
        <p style="margin-top: -1.45rem;">
          {{ item.text }}
        </p>
      </div>
      <div v-if="rail" class="pa-1 mt-2 text-overline" />
      <flowy-nav-menu
        :menu="item.items"
        :rail="rail"
        :index="index"
      />
    </div>
    <v-list-item
      active-class="text-primary"
      link
      @click="openDocs()"
    >
      <template #prepend>
        <v-icon :class="menuTextColor">
          mdi-clipboard-outline
        </v-icon>
      </template>
      <v-list-item-title :class="menuTextColor">
        Docs
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import FlowyNavMenu from './FlowyNavMenu'
import { useTheme } from 'vuetify'

export default {
  components: {
    FlowyNavMenu
  },
  props: {
    rail: {
      type: Boolean
    },
    menu: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      docs: 'https://getflowy.io/docs'
    }
  },
  computed: {
    isThemeDark() {
      const theme = useTheme()
      return theme.global.name.value === 'dark'
    },
    menuTextColor() {
      return {
        'custom-nav-color': !this.isThemeDark,
        'text-white': this.isThemeDark
      }
    }
  },
  methods: {
    openDocs() {
      window.open(this.docs, '_blank')
    }
  }
}
</script>
<style>

</style>
