export default {
  themes: {
    default: {
      light: {
        colors: {

          customBtnBgColor: '#adadad',
          customTabActive: '#FAFAFA',
          customTabBg: '#EEEEEE',

          customNavColor: '#64748B',
          customContainerBorder: '#d6d6d6',
          customContainerBg: '#ffffff',

          activeStatus: '#22C55E',
          activeStatusBg: '#c9ffde',
          activeStatusBorder: '#22C55E',

          deactiveStatus: '#DC2626',
          deactiveStatusBg: '#fac3c3',
          deactiveStatusBorder: '#EF4444',

          textbase: '#1f1f1f',
          background: '#ffffff',
          surface: '#f0eff4',
          primary: '#009fb7',
          secondary: '#6a6a6f',
          accent: '#ffd867',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#fecf65',
          timeline: '#f0eff4',
          timelineBg: '#ffffff',
          customBg: '#e9edef',
          customDrawerBg: '#e9edef',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#6a6a6f',
          customInputBorderColor: '#6a6a6f',
          customDrawerSelect: '#0094B0',
          customStepLogSelectBg: '#009fb7',
          customStepLogSelect: '#f0eff4',
          currentEntityTextColor: '#1f1f1f',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },
      dark: {
        colors: {

          customBtnBgColor: '#6a6a6f',
          customNavColor: '#FFFFFF',

          searchandpermBg: '#1C1C1C',
          customContainerBorder: '#6a6a6f',
          customContainerBg: '#101114',

          activeStatus: '#51A570',
          activeStatusBg: '#0D331C',
          activeStatusBorder: '#51A570',

          deactiveStatus: '#F05555',
          deactiveStatusBg: '#3D1212',
          deactiveStatusBorder: '#F05555',

          textbase: '#ecefef',
          background: '#0a0a0d',
          surface: '#101114',
          primary: '#009fb7',
          secondary: '#6a6a6f',
          accent: '#ffd867',
          error: '#FF5252',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#2a2b2a',
          timelineBg: '#0f0f0f',
          customBg: '#23272e',
          customDrawerBg: '#1B1C1C',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#6a6a6f',
          customInputBorderColor: '#6a6a6f',
          customDrawerSelect: '#009fb7',
          customStepLogSelectBg: '#009fb7',
          customStepLogSelect: '#2a2b2a',
          currentEntityTextColor: '#1f1f1f',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }

    },
    dark: {
      colors: {

        customBtnBgColor: '#6a6a6f',
        customNavColor: '#FFFFFF',

        searchandpermBg: '#1C1C1C',
        customContainerBorder: '#6a6a6f',
        customContainerBg: '#101114',

        activeStatus: '#51A570',
        activeStatusBg: '#0D331C',
        activeStatusBorder: '#51A570',

        deactiveStatus: '#F05555',
        deactiveStatusBg: '#3D1212',
        deactiveStatusBorder: '#F05555',

        textbase: '#ecefef',
        background: '#0a0a0d',
        surface: '#2a2b2a',
        primary: '#009fb7',
        secondary: '#6a6a6f',
        accent: '#ffd867',
        error: '#FF5252',
        info: '#2196F3',
        success: '#05c075',
        warning: '#febf08',
        timeline: '#2a2b2a',
        timelineBg: '#0f0f0f',
        customBg: '#23272e',
        customDrawerBg: '#1B1C1C',
        accentText: '#1f1f1f',
        tableBorderLinesColor: '#6a6a6f',
        customInputBorderColor: '#6a6a6f',
        customDrawerSelect: '#009fb7',
        customStepLogSelectBg: '#009fb7',
        customStepLogSelect: '#2a2b2a',
        currentEntityTextColor: '#1f1f1f',
        processDiagramFlowNodeBackground: '#FFE082',
        processDiagramSimpleNodeBackground: '#90CAF9'
      }
    },
    light: {
      colors: {

        customBtnBgColor: '#adadad',
        customTabActive: '#FAFAFA',
        customTabBg: '#EEEEEE',

        customNavColor: '#64748B',
        customContainerBorder: '#d6d6d6',
        customContainerBg: '#ffffff',

        activeStatus: '#22C55E',
        activeStatusBg: '#c9ffde',
        activeStatusBorder: '#22C55E',

        deactiveStatus: '#DC2626',
        deactiveStatusBg: '#fac3c3',
        deactiveStatusBorder: '#EF4444',

        textbase: '#1f1f1f',
        background: '#ffffff',
        surface: '#f0eff4',
        primary: '#009fb7',
        secondary: '#6a6a6f',
        accent: '#ffd867',
        error: '#ef476f',
        info: '#2196F3',
        success: '#05c075',
        warning: '#fecf65',
        timeline: '#f0eff4',
        timelineBg: '#ffffff',
        customBg: '#e9edef',
        customDrawerBg: '#e9edef',
        accentText: '#1f1f1f',
        tableBorderLinesColor: '#6a6a6f',
        customInputBorderColor: '#6a6a6f',
        customDrawerSelect: '#0094B0',
        customStepLogSelectBg: '#009fb7',
        customStepLogSelect: '#f0eff4',
        currentEntityTextColor: '#1f1f1f',
        processDiagramFlowNodeBackground: '#FFE082',
        processDiagramSimpleNodeBackground: '#90CAF9'
      }
    },
    goldenrod: {
      dark: {
        colors: {

          customTabActive: '#E0E0E0',
          customTabBg: '#757575',

          textbase: '#ecefef',
          background: '#0a0a0d',
          surface: '#2a2b2a',
          primary: '#c78b19',
          secondary: '#3a3f55',
          accent: '#897255',
          error: '#FF5252',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#2a2b2a',
          timelineBg: '#0f0f0f',
          customBg: '#1a262f',
          customDrawerBg: '#1a262f',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#3a3f55',
          customInputBorderColor: '#3a3f55',
          customDrawerSelect: '#c78b19',
          customStepLogSelectBg: '#c78b19',
          customStepLogSelect: '#2a2b2a',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },

      // light theme colors
      light: {
        colors: {

          customTabActive: '#F5F5F5',
          customTabBg: '#BDBDBD',

          textbase: '#1f1f1f',
          background: '#ffffff',
          surface: '#f0eff4',
          primary: '#af7608',
          secondary: '#393337',
          accent: '#fee55f',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#fecf65',
          timeline: '#f0eff4',
          timelineBg: '#ffffff',
          customBg: '#f1f5fa',
          customDrawerBg: '#f1f5fa',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#393337',
          customInputBorderColor: '#393337',
          customDrawerSelect: '#c78b19',
          customStepLogSelectBg: '#c78b19',
          customStepLogSelect: '#f0eff4',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }
    },
    magenta: {
      dark: {
        colors: {

          customTabActive: '#E0E0E0',
          customTabBg: '#757575',

          textbase: '#ecefef',
          background: '#0a0a0d',
          surface: '#2a2b2a',
          primary: '#A3006A',
          secondary: '#a39ba8',
          accent: '#4D7298',
          error: '#FF5252',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#2a2b2a',
          timelineBg: '#0f0f0f',
          customBg: '#14000E',
          customDrawerBg: '#30011E',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#a39ba8',
          customInputBorderColor: '#a39ba8',
          customDrawerSelect: '#A3006A',
          customStepLogSelectBg: '#A3006A',
          customStepLogSelect: '#2a2b2a',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },

      // light theme colors
      light: {
        colors: {

          customTabActive: '#F5F5F5',
          customTabBg: '#BDBDBD',

          textbase: '#1f1f1f',
          background: '#ffffff',
          surface: '#f0eff4',
          primary: '#672A4E',
          secondary: '#820B8A',
          accent: '#5EDDFD',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#fecf65',
          timeline: '#f0eff4',
          timelineBg: '#ffffff',
          customBg: '#FFD6F3',
          customDrawerBg: '#FFEBF9',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#820B8A',
          customInputBorderColor: '#820B8A',
          customDrawerSelect: '#A3006A',
          customStepLogSelectBg: '#A3006A',
          customStepLogSelect: '#f0eff4',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }
    },
    violet: {
      dark: {
        colors: {

          customTabActive: '#E0E0E0',
          customTabBg: '#757575',

          textbase: '#ecefef',
          background: '#0a0a0d',
          surface: '#2a2b2a',
          primary: '#A356F0',
          secondary: '#594771',
          accent: '#fec85a',
          error: '#FF5252',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#2a2b2a',
          timelineBg: '#0f0f0f',
          customBg: '#271F30',
          customDrawerBg: '#501D86',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#594771',
          customInputBorderColor: '#594771',
          customDrawerSelect: '#A356F0',
          customStepLogSelectBg: '#A356F0',
          customStepLogSelect: '#2a2b2a',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },

      // light theme colors
      light: {
        colors: {

          customTabActive: '#F5F5F5',
          customTabBg: '#BDBDBD',

          textbase: '#1f1f1f',
          background: '#ffffff',
          surface: '#f0eff4',
          primary: '#7A14E1',
          secondary: '#343434',
          accent: '#fae885',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#fecf65',
          timeline: '#f0eff4',
          timelineBg: '#ffffff',
          customBg: '#e7d9e7',
          customDrawerBg: '#d8cedf',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#343434',
          customInputBorderColor: '#343434',
          customDrawerSelect: '#A356F0',
          customStepLogSelectBg: '#A356F0',
          customStepLogSelect: '#f0eff4',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }
    },
    greenCrayola: {
      dark: {
        colors: {

          customTabActive: '#E0E0E0',
          customTabBg: '#757575',

          textbase: '#ecefef',
          background: '#0a0a0d',
          surface: '#2a2b2a',
          primary: '#b1d738',
          secondary: '#345995',
          accent: '#CBEAA6',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#2a2b2a',
          timelineBg: '#0f0f0f',
          customBg: '#2a2d25',
          customDrawerBg: '#718A2E',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#345995',
          customInputBorderColor: '#345995',
          customDrawerSelect: '#b1d738',
          customStepLogSelectBg: '#b1d738',
          customStepLogSelect: '#2a2b2a',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },

      // light theme colors
      light: {
        colors: {

          customTabActive: '#F5F5F5',
          customTabBg: '#BDBDBD',

          textbase: '#1f1f1f',
          background: '#ffffff',
          surface: '#f0eff4',
          primary: '#7E9933',
          secondary: '#345995',
          accent: '#03440C',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#fecf65',
          timeline: '#f0eff4',
          timelineBg: '#ffffff',
          customBg: '#fbfeee',
          customDrawerBg: '#CBEAA6',
          accentText: '#ffffff',
          tableBorderLinesColor: '#345995',
          customInputBorderColor: '#345995',
          customDrawerSelect: '#b1d738',
          customStepLogSelectBg: '#b1d738',
          customStepLogSelect: '#2a2b2a',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }
    },
    blue: {
      dark: {
        colors: {

          customTabActive: '#E0E0E0',
          customTabBg: '#757575',

          textbase: '#ecefef',
          background: '#0a0a0d',
          surface: '#2a2b2a',
          primary: '#227fea',
          secondary: '#2196F3',
          accent: '#ffd867',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#2a2b2a',
          timelineBg: '#0f0f0f',
          customBg: '#87aec4',
          customDrawerBg: '#005a90',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#518cc6',
          customInputBorderColor: '#518cc6',
          customDrawerSelect: '#227fea',
          customStepLogSelectBg: '#005a90',
          customStepLogSelect: '#ebebfe',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },

      // light theme colors
      light: {
        colors: {

          customTabActive: '#F5F5F5',
          customTabBg: '#BDBDBD',

          textbase: '#1f1f1f',
          background: '#ffffff',
          surface: '#f0eff4',
          primary: '#0065cd',
          secondary: '#000',
          accent: '#ffd867',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#fecf65',
          timeline: '#f0eff4',
          timelineBg: '#ffffff',
          customBg: '#bfecf2',
          customDrawerBg: '#e9edef',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#000',
          customInputBorderColor: '#000',
          customDrawerSelect: '#227fea',
          customStepLogSelectBg: '#227fea',
          customStepLogSelect: '#f0eff4',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }
    },
    simple: {
      dark: {
        colors: {

          customTabActive: '#E0E0E0',
          customTabBg: '#757575',

          textbase: '#ecefef',
          background: '#0a0a0d',
          surface: '#2a2b2a',
          primary: '#009fb7',
          secondary: '#6a6a6f',
          accent: '#ffd867',
          error: '#FF5252',
          info: '#2196F3',
          success: '#05c075',
          warning: '#febf08',
          timeline: '#2a2b2a',
          timelineBg: '#0f0f0f',
          customBg: '#1B1C1C',
          customDrawerBg: '#1B1C1C',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#6a6a6f',
          customInputBorderColor: '#6a6a6f',
          customDrawerSelect: '#009fb7',
          customStepLogSelectBg: '#009fb7',
          customStepLogSelect: '#2a2b2a',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      },

      // light theme colors
      light: {
        colors: {

          customTabActive: '#F5F5F5',
          customTabBg: '#BDBDBD',

          textbase: '#1f1f1f',
          background: '#ffffff',
          surface: '#f0eff4',
          primary: '#009fb7',
          secondary: '#6a6a6f',
          accent: '#ffd867',
          error: '#ef476f',
          info: '#2196F3',
          success: '#05c075',
          warning: '#fecf65',
          timeline: '#f0eff4',
          timelineBg: '#ffffff',
          customBg: '#e9edef',
          customDrawerBg: '#e9edef',
          accentText: '#1f1f1f',
          tableBorderLinesColor: '#6a6a6f',
          customInputBorderColor: '#6a6a6f',
          customDrawerSelect: '#009fb7',
          customStepLogSelectBg: '#009fb7',
          customStepLogSelect: '#f0eff4',
          processDiagramFlowNodeBackground: '#FFE082',
          processDiagramSimpleNodeBackground: '#90CAF9'
        }
      }
    }
  }
}
