<template>
  <v-menu
    :offset="[0, 95]"
    location="left"
    transition="slide-y-transition"
    class="user-list-position"
  >
    <template #activator="{ props }">
      <v-btn
        icon
        class="elevation-0"
        v-bind="props"
      >
        <v-avatar size="40" data-cy="profile-icon">
          <v-icon size="large" :color="isThemeDark ? 'accent' : 'primary'">
            mdi-account
          </v-icon>
        </v-avatar>
      </v-btn>
    </template>

    <!-- user menu list -->
    <v-list density="compact">
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        :exact="item.exact"
        :disabled="item.disabled"
        link
        :to="{ name: item.value }"
      >
        <template #prepend>
          <v-icon
            size="small"
            :class="{ 'text-grey': item.disabled }"
            data-cy="profile-item"
          >
            {{ item.icon }}
          </v-icon>
        </template>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>

      <v-divider class="my-1" />

      <v-list-item
        v-for="(item, index) in themes"
        :key="index + 1000"
        :disabled="item.disabled"
        @click="$emit('changeTheme', item.value)"
      >
        <template #prepend>
          <v-icon :class="{ 'text-primary': isThemeDark === item.value }">
            {{ item.icon }}
          </v-icon>
        </template>
        <v-list-item-title :class="{ 'text-primary': isThemeDark === item.value }">
          {{ item.text }}
        </v-list-item-title>
      </v-list-item>

      <v-divider class="my-1" />

      <v-list-item link @click="logout()">
        <template #prepend>
          <v-icon size="small">
            mdi-logout-variant
          </v-icon>
        </template>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import auth from '@/auth/index'
import { useTheme } from 'vuetify'
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
export default {
  data() {
    return {
      themes: [
        { icon: 'mdi-weather-sunny', text: 'Light', value: false },
        { icon: 'mdi-moon-waning-crescent', text: 'Dark', value: true }
      ],
      menu: [
        { icon: 'mdi-account-box-outline', text: 'Profile', value: 'profile', exact: true }
      ]
    }
  },
  computed: {
    isThemeDark() {
      const theme = useTheme()
      return theme.global.name.value === 'dark'
    }
  },
  methods: {
    logout() {
      auth.logout(true)
    }
  }
}
</script>
