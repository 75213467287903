import { createI18n } from "vue-i18n";
import deActions from "@/lang/de/actions";
import enActions from "@/lang/en/actions";
import deDays from "@/lang/de/days";
import enDays from "@/lang/en/days";
import deErrors from "@/lang/de/errors";
import enErrors from "@/lang/en/errors";
import deGlobal from "@/lang/de/global";
import enGlobal from "@/lang/en/global";
import deHeader from "@/lang/de/header";
import enHeader from "@/lang/en/header";
import deHints from "@/lang/de/hints";
import enHints from "@/lang/en/hints";
import deLabels from "@/lang/de/labels";
import enLabels from "@/lang/en/labels";
import deMonths from "@/lang/de/months";
import enMonths from "@/lang/en/months";
import deRoutes from "@/lang/de/routes";
import enRoutes from "@/lang/en/routes";
import deStatus from "@/lang/de/status";
import enStatus from "@/lang/en/status";
import deSuccess from "@/lang/de/success";
import enSuccess from "@/lang/en/success";

export default createI18n({
  locale: localStorage.selectedLanguage
    ? localStorage.selectedLanguage
    : 'en',
  fallbackLocale: 'en',
  messages: {
    de: {
      actions: deActions,
      days: deDays,
      errors: deErrors,
      global: deGlobal,
      header: deHeader,
      hints: deHints,
      labels: deLabels,
      months: deMonths,
      routes: deRoutes,
      status: deStatus,
      success: deSuccess
    },
    en: {
      actions: enActions,
      days: enDays,
      errors: enErrors,
      global: enGlobal,
      header: enHeader,
      hints: enHints,
      labels: enLabels,
      months: enMonths,
      routes: enRoutes,
      status: enStatus,
      success: enSuccess
    }
  }
})