module.exports = {
  home: 'Home',
  credentials: 'Credentials',
  triggers: 'Triggers',
  cron: 'Cron',
  rest: 'Rest',
  logs: 'Logs',
  logsSteps: 'Steps',
  logsEvents: 'Events',
  pages: 'Pages',
  processes: 'Processes',
  permissions: 'Permissions',
  users: 'Users',
  roles: 'Roles',
  templates: 'Templates',
  translations: 'Translations',
  settings: 'Settings',
  frontend: 'Frontend',
  instance: 'Instance',
  smtp: 'Smtp',
  modules: 'Modules',
  validations: 'Validations',
  process: 'Process',
  local: 'Local',
  hub: 'Hub',
  gdpr: 'GDPR',
  gdprCronTrigger: 'Cron Trigger',
  gdprRestTrigger: 'Rest Trigger',
  gdprModules: 'Modules',
  gdprProcessCredentials: 'Process Credentials',
  gdprProcesses: 'Processes',
  plugins: 'Plugins',
  instances: 'Instances',
  messaging: 'Messaging',
  instanceProcessing: 'Instance Processing',
  instanceAdmin: 'Instance Admin',
  telemetries: 'Telemetries',
  date: 'Date',
  links: 'Links',
  history: 'History',
  massChanges: 'Mass Changes',
  admin: 'Admin',
  entities: 'Entities',
  library: 'Library',
  libraries: 'Libraries',
  gdprLibraries: 'Libraries',
  gdprMessagingTrigger: 'Messaging Trigger',
  storage: 'Storage',
  eventsHandler: 'Events Handler',
  gdprEntities: 'Entities',
  global: 'Global',
  browseData: 'Browse Data'
}
