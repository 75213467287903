<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { defineComponent, provide } from 'vue';
import { useTheme } from 'vuetify';

export default defineComponent({
  name: 'ThemeOptionsAPIProvider',
  setup() {
    const theme = useTheme();
    provide('theme', theme);
    return {};
  }
});
</script>
