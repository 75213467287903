import { createVuetify } from 'vuetify'

import config from '../configs'

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '../assets/scss/theme.scss'

import '@mdi/font/css/materialdesignicons.css'
/**
 * Vuetify Plugin
 * Main components library
 *
 * https://vuetifyjs.com/
 *
 */

export default createVuetify({
  components, // Include Vuetify components
  directives, // Include Vuetify directives
  icons: {
    defaultSet: 'mdi' // Use Material Design Icons
  },
  theme: {
    defaultTheme: 'light', // Default theme to light
    themes: config.theme.themes
  },
  options: {
    customProperties: true // Enable custom CSS properties
  }
});
