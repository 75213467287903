<template>
  <v-menu
    :offset="[0, 95]"
    location="left"
    transition="slide-y-transition"
    class="lang-list-position"
  >
    <template #activator="{ props }">
      <v-btn
        icon
        class="elevation-0"
        v-bind="props"
      >
        <v-avatar size="32">
          <svg
            :style="`width: 100%;height: 100%;vertical-align: middle;overflow: hidden; fill: ${isThemeDark ? 'rgb(var(--v-theme-accent))' : 'rgb(var(--v-theme-primary))' }`"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          ><path d="M547.797333 638.208l-104.405333-103.168 1.237333-1.28a720.170667 720.170667 0 0 0 152.490667-268.373333h120.448V183.082667h-287.744V100.906667H347.605333v82.218666H59.818667V265.386667h459.178666a648.234667 648.234667 0 0 1-130.304 219.946666 643.242667 643.242667 0 0 1-94.976-137.728H211.541333a722.048 722.048 0 0 0 122.453334 187.434667l-209.194667 206.378667 58.368 58.368 205.525333-205.525334 127.872 127.829334 31.232-83.84m231.424-208.426667h-82.218666l-184.96 493.312h82.218666l46.037334-123.306667h195.242666l46.464 123.306667h82.218667l-185.002667-493.312m-107.690666 287.744l66.56-178.005333 66.602666 178.005333z" fill="" /></svg>
        </v-avatar>
      </v-btn>
    </template>

    <!-- languages menu list -->
    <v-list density="compact">
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        :exact="item.exact"
        :disabled="item.disabled"
        @click="$emit('changeLang', item.value)"
      >
        <template #prepend>
          <v-icon :class="{ 'text-primary': $current_lang === item.value }">
            {{ item.icon }}
          </v-icon>
        </template>
        <v-list-item-title :class="{ 'text-primary': $current_lang === item.value }">
          {{ item.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
import { useTheme } from 'vuetify'

export default {
  data() {
    return {
      menu: [
        { icon: 'mdi-earth', text: 'English', value: 'en' },
        { icon: 'mdi-earth', text: 'Deutsch', value: 'de' }
      ]
    }
  },
  computed: {
    isThemeDark() {
      const theme = useTheme()
      return theme.global.name.value === 'dark'
    }
  }
}
</script>
