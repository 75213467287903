export function createLangWrapper(t) {
  return new Proxy({}, {
    get(target, key) {
      if (typeof key !== 'string') {
        return undefined;
      }
      // Handle nested properties like 'labels.hello'
      return new Proxy({}, {
        get(innerTarget, innerKey) {
          if (typeof innerKey !== 'string') {
            return undefined;
          }
          return t(`${key}.${innerKey}`);
        }
      });
    }
  });
}