import { createLangWrapper } from './localizationWrapper';

// To use new localization library with old syntax from 'vuejs-localization'
export default {
  install(app, i18n) {
    if (!i18n?.global?.t) {
      throw new Error('i18n instance must be provided to localizationCompatPlugin');
    }

    app.config.globalProperties.$lang = createLangWrapper(i18n.global.t);

    const lang = localStorage.selectedLanguage
      ? localStorage.selectedLanguage
      : 'en'

    app.config.globalProperties.$current_lang = lang;

    app.config.globalProperties.$setLang = (lang) => {
      localStorage.selectedLanguage = lang;
      app.config.globalProperties.$current_lang = lang;
    }
  }
};
